import React, { useContext, useState } from "react"
import styled from "styled-components"
import Form from "react-bootstrap/Form"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import CartContext from "../contexts/cartcontext"
import { loadStripe } from "@stripe/stripe-js"
import { useAuth } from "../contexts/authcontext"
import firebase from "gatsby-plugin-firebase"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

const StyledContainer = styled.div`
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
`

export default function CartCheckout() {
  const cntxt = useContext(CartContext)
  const [loading, setLoading] = useState(false)
  const lineItems = cntxt.itemsPurchased
  const [msg, setMsg] = useState("")
  const { currentUser } = useAuth()
  const uid = currentUser.uid

  const handleChange = e => {
    setMsg(e.target.value)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setLoading(loading)
    const fileRef = sessionStorage.getItem("DocRef")
    const message = msg
    const userRef = firebase
      .firestore()
      .doc(`users/${uid}`)
      .collection(`readings/`)
    const messageRef = userRef.doc(`${fileRef}`)
    try {
      const res = messageRef.update({
        message: message,
      })
    } catch (error) {
      console.error("Error adding data", error)
    }

    fetch(`${process.env.GATSBY_SERVERLESS_BASE}/checkoutCreate`, {
      method: "POST",
      body: JSON.stringify(lineItems),
    })
      .then(async response => {
        const { id } = await response.json()
        localStorage.setItem("id", id)
        const stripe = await stripePromise
        const { error } = await stripe.redirectToCheckout({ sessionId: id })
        console.log("Response:", error.message)
      })
      .catch(err => console.log("catch", err.message))
    setLoading(!loading)
  }

  return (
    <StyledContainer>
      <Card className="w-100 m-1 p-1" style={{ maxWidth: `50rem` }}>
        <Form
          name="messageform"
          onSubmit={handleSubmit}
          style={{ background: `transparent`, border: `none` }}
        >
          <Card.Header className="text-center m-1 p-1">
            <h3>Ask A Specific Question</h3>
          </Card.Header>
          <Card.Body>
            <Card.Text
              style={{
                color: `var(--gold)`,
                fontFamily: `poppins, sans-serif`,
                fontSize: `1.5rem`,
              }}
            >
              {currentUser.displayName}, here's your chance to make your reading
              quite focused and specific. If that's not for you, hit "Checkout"
              below to proceed.
            </Card.Text>
            <Form.Group id="message">
              <Form.Label>
                Enter your specific question or issue here:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="10"
                value={msg}
                onChange={handleChange}
              />
            </Form.Group>
            <Card.Text className="tandccheck">
              By checking out you confirm your acceptance of Ask The Tarot's{" "}
              <a
                href="https://readings.askthetarot.com.au/terms"
                target="_blank"
                rel="noreferrer"
              >
                terms & conditions
              </a>
            </Card.Text>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center">
            <Button className="checkOut w-100" disabled={loading} type="submit">
              checkout
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </StyledContainer>
  )
}
