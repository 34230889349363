import React from "react"
import styled from "styled-components"
import CartCheckout from "../components/cartcheckout"
import SEO from "../components/seo"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem auto;
`

export default function PurchaseFinalisePage() {
  return (
    <StyledContainer>
      <SEO title="Purchase Finalise" />
      <CartCheckout />
    </StyledContainer>
  )
}
